import React from 'react'
import Helmet from 'react-helmet'

import Layout from '../../components/layout'
import style from "./checker.module.scss"

import phoneValidator from "../../utils/phoneValidator"
import checkPhoneNumber from "../../api/fbLeakChecker"

const HomeIndex = () => {



  const siteTitle = 'Balance ton hacker'


  const [phoneNumber, setPhoneNumber] = React.useState(null)
  const [pawned, setPawned] = React.useState(0)
  const [loading, setLoading] = React.useState(false)
  const [pawnedDatas, setPawnedDatas] = React.useState([])

  async function handleSubmit(e) {
    e.preventDefault()

    const entryIsValid = (phoneNumber && phoneNumber.length) ? phoneValidator(phoneNumber) : false

    if (phoneNumber && entryIsValid) {
      setLoading(true)
      checkPhoneNumber(phoneNumber)
        .then(res => {
          setLoading(false)
          if (res.data.data && res.data.data.length) {
            let tmpData = []
            for (const [key, value] of Object.entries(res.data.data[0])) {
              if (value) {
                tmpData.push(key)
                console.log(key)
              }
            }
            setPawnedDatas(tmpData)
            return setPawned(1)
          } else {
            return setPawned(2)
          }
        })
        .catch(err => {
          console.log(err)
          setLoading(false)
        })
    } else {
      return setPawned(3)
    }
    return setPawned(0)
  }

  React.useEffect(() => {
    console.log(pawned)
  }, [pawned])

  function renderPawned() {
    switch (pawned) {
      case 0:
        return null
      case 1:
        return <p className={style.red}>Nous avons trouvé ce numéro de téléphone dans la liste. Si vous utilisez ce numéro de téléphone depuis juillet 2019, il est possible que les données associées suivantes soit exploitées à des fins malveillantes : nom, prénom, e-mail, genre, ville, pays, date de naissance. Pour apprendre à mieux vous protéger en ligne rendez-vous sur Instagram <a href="https://instagram.com/balancetonhacker">@balancetonhacker</a>.</p>
      case 2:
        return <p className={style.green}>Vos données ne sont pas compromises. Restez vigilant et naviguez couvert. Pour apprendre à mieux vous protéger en ligne rendez-vous sur Instagram <a href="https://instagram.com/balancetonhacker">@balancetonhacker</a>.</p>
      case 3:
        return <p>Veuillez renseigner un numéro de téléphone valide (numéro français uniquement pour le moment).</p>
      default:
        return null
    }
  }

  return (
    <Layout>
      <Helmet>
        <title>{siteTitle} - Vérifiez si vos données personnelles ont été compromises</title>
        <meta name="description" content="Le 3 avril 2021, une liste a été partagé publiquement sur un forum de hackers exposant les données personnelles de 533 millions d'ulisateurs de Facebook." />
      </Helmet>

      <div className="vh-100" id="main">
        <div className={style.checkerWrapper}>
          <h1>Vérifiez si vos données Facebook ont fuité.</h1>
          <div className={style.checkerPresentation}>En juillet 2019, les données de près de 233 millions de comptes sur Facebook ont fuité. Le 3 avril 2021, cette liste a été partagé publiquement sur un forum de hackers exposant les données personnelles de millions de personnes.</div>
          <div className={style.checkerStatsWrapper}>
            <div className={style.checkerStatsBox}>
              <p className={style.stat}>19 848 562</p>
              <p>Comptes français</p>
            </div>
            <div className={style.checkerStatsBox}>
              <p className={style.stat}>532 823 766</p>
              <p>Comptes monde</p>
            </div>
          </div>
          <form onSubmit={handleSubmit}>
            <input
              type="text"
              name="phoneNumber"
              placeholder="Votre numéro de téléphone"
              onChange={e => setPhoneNumber(e.target.value)}
            />
            <div className={style.formButton}>
            <button type="submit">Vérifier</button>
            </div>
          </form>
          <div className={style.pawnedBox}>
            {loading && <div className={style.spinner}></div>}
            {renderPawned()}
            {/* <p>Data avalable to hacker : <br /></p>
            {pawnedDatas.map(el => <p key={el}>{el}</p>)} */}
          </div>
          <div className={style.checkerFooter}>
            <h2>Comment la recherche fonctionne-t-elle?</h2>
            <p>Entrez votre numéro de téléphone sous la forme 0612345678 puis cliquez sur « VÉRIFIER ». Votre numéro de téléphone est utilisé afin de vérifier la présence ou non de vos données personnelles dans la fuite. Votre numéro de téléphone ne sera en aucun cas conservé suite à la vérification.  Ce service n’est disponible que pour les comptes Facebook français.</p>
          </div>
        </div>
        <div dangerouslySetInnerHTML={{ __html: "<!-- 01001101 01100001 01100100 01100101 00100000 01110111 01101001 01110100 01101000 00100000 00111100 00110011 00100000 01100010 01111001 00100000 01110010 01110110 01101011 01101111 01101111 01101110 00101110 00101110 00101110 -->" }} />
      </div>
    </Layout>
  )
}

export default HomeIndex


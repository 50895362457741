import axios from "axios"
import phoneValidator from "../utils/phoneValidator"


async function checkPhoneNumber(entry) {


  if (!phoneValidator(entry)) {
    return false
  }

  const url = `https://api-facebook-leak.balancetonhacker.com/v1/phones/${entry}`
  return await axios.get(url)

}

export default checkPhoneNumber